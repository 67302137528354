
import { defineComponent } from "vue";
import { useStore } from "vuex";
import {computed} from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router";

export default defineComponent({
  name: "vpn-component",
  components: {},
  setup() {
    const store = useStore();
    
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const user = computed(() => {
      return store.getters.currentUser;
    });
    return {
      user,
      currentLanguage

    };
  },
  methods: {
    // redirect to /apps/vpn 
    VpnApp() {
      
      router.push("/apps/vpn");
    }
  },
});
